<template>
  <div>

    <BreadCrumbs>خانه > بانک آگهی > {{ad.title}} </BreadCrumbs>

    <v-container class="d-flex flex-wrap pa-0 mt-5">

      <div class="col-md-6 mt-5 mt-md-0 ma-0 order-1 order-md-0" >
        <div v-if="ad.employ" class="d-flex justify-end">
        <div class="employsingle">آگهی استخدام</div>
        </div>
        <h3 class=" primary--text">{{ad.title}}</h3>
        <div class="d-flex justify-space-between mt-2 mb-3">
          <small class="primary--text">
            شرکت آگهی دهنده
            :
            <router-link :to="{name:'company',params:{id:ad.workId,title:ad.workTitle}}" custom v-slot="{navigate,href}">
            <a @click="navigate" :href="href" role="link" class="textlinkhover" style="color: black"  >{{ad.workTitle}}</a>
            </router-link>
          </small>
          <small class="primary--text">
            {{ad.createTime | momentDate}}
          </small>

        </div>
        <p class="primary--text">
           توضیحات :
          <br>
          <span class="grey--text">

            {{ad.desc}}

          </span>
        </p>
        <p class="primary--text">
          شماره تماس :
          <a class="textlinkhover" style="color: black;text-decoration: none" :href="'tel:'+ad.tell" >{{ad.tell}}</a>
        </p>
        <p class="primary--text">
          قیمت :
          <span class="black--text" >{{ad.price | numFormat}}</span>
          <span class="black--text" > تومان </span>
        </p>
        <p class="primary--text">
          مهلت تا :
          <span class="black--text" >{{ad.expireTime | momentDate}}</span>
        </p>
      </div>
      <div class="col-md-6 ma-0 order-0 order-md-1" >
        <AdSlider :image="ad.image" :images="ad.images"></AdSlider>
      </div>

    </v-container>
  </div>
</template>

<script>


import Vue from 'vue'

import axios from "axios";
import AdSlider from "../components/AdSlider";
import BreadCrumbs from "../components/BreadCrumbs";
import moment from "jalali-moment";
import numeral from 'numeral';
import numFormat from 'vue-filter-number-format';

Vue.filter('numFormat', numFormat(numeral));
export default {
  name: 'App',
  filters : {
    momentDate(date){
      return moment.unix(date).locale('fa').format('jYYYY/jMM/jDD');
    },
  },
  metaInfo() {
    return {
      title: this.ad.title
      ,link: [{rel: 'canonical', href: this.$store.state.url+'/ads/single/'+this.id+'/'+this.ad.title}],
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: this.ad.desc,
        },
        {
          property: 'og:description',
          content: this.ad.desc,
          vmid: 'og:description'
        },

        {
          property: 'og:image',
          vmid: 'og:image',
          content: this.$store.state.thumbnail + this.ad.image
        }, {
          property: 'og:title',
          vmid: 'og:title',
          content: 'ایران آی سیب' + '::' + this.ad.title
        }
      ],
    }
  },
  components : {
    AdSlider,
    BreadCrumbs
  },
  data() {
    return {
      id : this.$route.params.id,
      ad : null,
    }
  },
  created() {
    axios.get(this.$store.state.api + 'singleads' , {
      params : {
        id : this.id,
      }
    }).then(response=>{
      this.ad = response.data.data;
    });
  }
};
</script>

<style lang="scss">
.employsingle{
  padding: 0 8px;
  font-size: 12px;
  user-select: none;
  border: 2px solid #8b0b86;
  border-radius: 5px;
  color: #570654;
  display: inline-block;
}
</style>
