<template>
<div>

  <v-dialog
      v-model="showimage"
      max-width="800"
  >
    <v-img contain :src="$store.state.upload + dialogImage" width="100%" max-height="500px" style="background-color: grey">
      <template v-slot:default>
        <div>
        <svg @click="showimage=false" style="width:24px;height:24px;cursor: pointer;margin-top: 5px;margin-right: 5px" viewBox="0 0 24 24">
          <path fill="white" d="M12,2C17.53,2 22,6.47 22,12C22,17.53 17.53,22 12,22C6.47,22 2,17.53 2,12C2,6.47 6.47,2 12,2M15.59,7L12,10.59L8.41,7L7,8.41L10.59,12L7,15.59L8.41,17L12,13.41L15.59,17L17,15.59L13.41,12L17,8.41L15.59,7Z" />
        </svg>
        </div>
        <a :href="$store.state.upload + dialogImage" target="_blank">
        <div style="height: 100%;width: 100%;cursor: pointer">

        </div>
        </a>
      </template>

    </v-img>
  </v-dialog>

  <swiper :navigation="true" class="swiper-images" :options="swiperOption">
    <swiper-slide v-if="image">
      <div  style="width: 100%" >
        <v-img
            width="100%"
            :src="$store.state.thumbnail+image"
            @click="showDialog(image)"
            style="cursor: zoom-in"
        >

          <div ref="imagecounter" style="width: 75px;cursor: default" class="profile__adsimagedesc d-flex justify-space-around align-center">
            <p class="mr-1 mb-0" style="font-size: 14px">1 از {{images.length+1}}</p>
            <img class="mb-1" src="../assets/image/Ads/camera.png" height="32" width="32"/>
          </div>

<!--          <div ref="report" style="width: 75px;cursor: pointer" class="profile__adsreport d-flex align-center">
            <img src="../assets/image/Ads/report.png" height="32" width="32"/>
            <p class="ml-1 mb-0" style="font-size: 12px;">گزارش</p>
          </div>-->

        </v-img>
      </div >

    </swiper-slide>

    <swiper-slide v-for="(item,i) in images" :key="item + 's'">
      <div style="width: 100%">
        <v-img
            width="100%"
            :src="$store.state.thumbnail+item"
            @click="showDialog(item)"
            style="cursor: zoom-in"
        >
          <div style="width: 75px;cursor: default" class="profile__adsimagedesc d-flex justify-space-around align-center">
            <p v-if="image.length>0" class="mr-1 mb-0" style="font-size: 14px">{{i + 2}} از {{images.length + 1}}</p>
            <p v-else class="mr-1 mb-0" style="font-size: 14px">{{i + 1}} از {{images.length}}</p>
            <img class="mb-1" src="../assets/image/Ads/camera.png" height="32" width="32"/>
          </div>
<!--          <div style="width: 75px;cursor: pointer" class="profile__adsreport d-flex align-center">
            <img src="../assets/image/Ads/report.png" height="32" width="32"/>
            <p class="ml-1 mb-0" style="font-size: 12px;">گزارش</p>
          </div>-->
        </v-img>

      </div>
    </swiper-slide>

    <div style="color: white !important;" class="swiper-button-next swiper-button-white navsize"  slot="button-next"></div>
    <div style="color: white !important;" class="swiper-button-prev swiper-button-white navsize"  slot="button-prev"></div>
  </swiper>
</div>
</template>

<script>
import {Swiper, SwiperSlide} from 'vue-awesome-swiper'
import SwiperCore, {EffectFade} from 'swiper';
import {Navigation} from 'swiper';
SwiperCore.use([Navigation]);
SwiperCore.use([EffectFade]);
export default {
  name: 'App',
  props : {
    image : null,
    images : null,
  },

  data() {
    return {
      dialogImage: null,
      showimage : false,
      swiperOption: {
        spaceBetween: 10,
        effect: 'fade',
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      }
    }
  },
  components:{
    Swiper,
    SwiperSlide,
  },
  methods : {
    showDialog(image){
        this.dialogImage = image;
        this.showimage=true;
    }
  }
};
</script>


<style lang="scss" scoped>

@import "../assets/css/sliders";

.navsize * {
  width: 5% !important;
  --swiper-navigation-size: 42px !important;
}

.profile__adsimagedesc {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  width: 110px;
  height: 35px;
  background-color: rgba(0, 0, 0, 0.5);
  right: 0;
  position: absolute;
  bottom: 10%;
  color: white;
  border-bottom-left-radius: 30px;
  border-top-left-radius: 30px;
}

.profile__adsreport {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  width: 110px;
  height: 35px;
  background-color: rgba(0, 0, 0, 0.5);
  left: 0;
  position: absolute;
  bottom: 10%;
  color: white;
  border-bottom-right-radius: 30px;
  border-top-right-radius: 30px;
}

 .swiper-container-rtl .swiper-button-prev:after {
  content: 'next' !important;
}

.swiper-button-prev:after, .swiper-button-next:after {
  font-size: 42px !important;
  color: white !important;
  text-shadow: 0px 0px 5px #000000;
}

</style>


<style scoped>
.swiper-images{
  background-color: #fff !important;
}
</style>
